<template>
  <WeContainer wide="" card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="feature.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="saveForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <!-- Feature Name -->
      <WeInput
        label="Özellik Adı"
        placeholder="Özellik Adı"
        name="feature_name"
        v-bind:required="true"
        v-model="form.name"
        v-bind:error="$v.form.name.$error"
      />
      <!-- ./Feature Name -->

      <!-- Value Name -->
      <div class="row align-items-end mb-4">
        <div class="col-12 col-md mb-3 mb-md-0">
          <WeInput
            label="Değer Adı"
            name="valueName"
            class="mb-0"
            v-bind:required="true"
            v-model="form.value.name"
            v-bind:error="$v.form.values.$error"
            v-on:keyup-enter="addNewFeature"
            ref="nameInput"
          />
        </div>
        <div class="col-12 col-md mb-3 mb-md-0">
          <WeInput
            label="Değer Kodu"
            v-model="form.value.code"
            class="mb-0"
            v-on:keyup-enter="addNewFeature"
          />
        </div>
        <div class="col-auto" style="margin-top: -15px" v-if="useColorPicker">
          <WeInput
            v-bind:label="form.name"
            v-model="form.value.color"
            class="mb-0"
            v-on:keyup-enter="addNewFeature"
          >
            <div slot="append" class="input-group-text">
              <verte
                picker="square"
                model="hex"
                v-bind:show-history="false"
                menu-position="right"
                v-model="form.value.color"
              ></verte>
            </div>
          </WeInput>
        </div>
        <div class="col-auto">
          <span class="btn btn-primary" v-on:click="addNewFeature()">Ekle</span>
        </div>
      </div>
      <!-- ./Value Name -->

      <!-- Added Values -->
      <div v-if="form.values && form.values.length">
        <ul class="list-group mb-3" style="border">
          <li
            class="list-group-item btn-white"
            v-for="(feature, i) in form.values"
            v-bind:key="i"
          >
            <div class="row align-items-end">
              <!-- Value Name -->
              <div class="col-12 col-md mb-2 mb-md-0">
                <small class="d-block d-md-none">Değer</small>
                <WeInput
                  type="text"
                  v-bind:class="{ 'border-danger': !feature.name }"
                  v-model="feature.name"
                />
              </div>
              <!-- Value Name -->

              <!-- Value Code -->
              <div class="col-12 col-md mb-2 mb-md-0">
                <small class="d-block d-md-none">Kod</small>
                <WeInput type="text" v-model="feature.code" />
              </div>
              <!-- Value Code -->

              <!-- Color Value -->
              <div
                class="col-auto"
                style="margin-top: -15px"
                v-if="useColorPicker"
              >
                <WeInput
                  v-model="feature.color"
                  class="mb-0"
                  v-on:keyup-enter="addNewFeature"
                >
                  <div slot="append" class="input-group-text">
                    <verte
                      picker="square"
                      model="hex"
                      v-bind:show-history="false"
                      menu-position="right"
                      v-model="feature.color"
                    ></verte>
                  </div>
                </WeInput>
              </div>
              <!-- ./Color Value -->

              <!-- Add Feature -->
              <div class="col-12 col-md-auto text-center">
                <span
                  class="btn btn-outline-danger action-btn"
                  v-on:click.prevent="removeItem(feature)"
                >
                  <i class="fas fa-trash"></i>
                </span>
              </div>
              <!-- Add Feature -->
            </div>
          </li>
        </ul>
      </div>
      <!-- ./Added Values -->
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import Verte from "verte";
import "verte/dist/verte.css";
export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      form: {
        name: null, // Feature Name
        values: [], // Added values
        value: {
          name: null,
          code: null,
        },
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      values: {
        required,
        minLength: minLength(1),
      },
    },
  },
  components: {
    Verte,
  },
  methods: {
    ...mapActions("feature", ["create", "update", "getById", "resetForm"]),
    ...mapMutations("feature", ["addFeature", "removeItem"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    addFeature(form) {
      form.values.push(form.value);
      form.value = {
        name: null,
        code: null,
      };
    },
    removeItem(item) {
      this.form.values.splice(this.form.values.indexOf(item), 1);
    },

    addNewFeature() {
      if (this.form.value.name) {
        if (this.checkFeatureNameExists()) {
          this.$swal({
            title: "Hata",
            text: "Bu değer daha önceden eklenmiş",
            icon: "error",
            confirmButtonText: "Tamam",
          });
        } else {
          this.addFeature(this.form);
        }
      }
      this.$refs.nameInput.$refs.input.focus();
    },
    checkFeatureNameExists() {
      return this.form.values.filter((fv) => fv.name === this.form.value.name)
        .length;
    },
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    saveForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.updateFeature();
      } else {
        this.createFeature();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Özellikler listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    createFeature() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateFeature() {
      this.update({
        id: this.$route.params.id,
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getFeatureById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/features");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState(["feature", "shared"]),
    getTitle() {
      return this.$route.params.id ? "ÖZELLİK DÜZENLE" : "ÖZELLİK EKLE";
    },
    checkPermission() {
      return permission.check("feature", "u");
    },
    useColorPicker() {
      const colorNames = ["renk", "color", "colour"];
      if (this.form.name && this.form.name.length) {
        let lowerValue = this.form.name.toLowerCase();
        return colorNames.includes(lowerValue);
      }
    },
  },
  mounted() {
    this.getFeatureById();
  },
  watch: {
    "feature.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getFeatureById();
        }
      },
    },
  },
};
</script>
